import React, { useEffect } from "react";
import { graphql } from 'gatsby';
import { loadStripe } from '@stripe/stripe-js';
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";
import Layout from "../../Layout";
import TwoColumnContent from "../../components/Products/TwoColumnContent";
import * as style from "../../components/Content/Content.module.css";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISH_KEY);

async function stripeLoader() {
    const stripe = await stripePromise

    const { error } = await stripe.redirectToCheckout({
        mode: "payment",
        lineItems: [{
            price: process.env.GATSBY_STRIPE_FINTECH_UIKIT_PRICE,
            quantity: 1
        }],
        successUrl: process.env.GATSBY_STRIPE_SUCCESS_URL,
        cancelUrl: 'https://michaellisboa.com/products/fintech-currency-transfer-ui-kit/'
    })
    if (error) {
        console.warn("Error:", error)
    }
    return stripe;
}

const UxDeliverables = ({ data, location, ...props }) => {

    const {
        heroImage,
        title,
        subtitle,
        slug,
        contentComponents: entries,
    } = data.contentfulProducts

    const ogImg = `https://michaellisboa.com${heroImage.localFile.publicURL}`
    const pageTitle = `Hypermix F/uX UI Kit - ${title}`
    const description = subtitle.subtitle
    const keywords = "user experience, ux design deliverables, product design, ux research, UX documentation"

    useEffect(
        () => {
            const a = document.querySelectorAll('A')
            for (let val of Object.values(a)) {
                if (val.getAttribute('title') && val.getAttribute('title') === 'stripeLoader') {
                    val.classList.add('uk-text-mustard')
                    val.setAttribute('href', '#')
                    val.removeAttribute('data-uk-scroll')
                    val.removeAttribute('title')
                    val.addEventListener('click', function (e) {
                        e.preventDefault();
                        stripeLoader()
                    }, false)
                }
            }
        }, []
    )

    return (
        <Layout location={location}>
            <Helmet>
                <title>{pageTitle}</title>
                <meta property="og:title" content={pageTitle} />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta name="twitter:description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="og:keywords" content={keywords} />
                <meta name="twitter:keywords" content={keywords} />
                <meta name="image" content={ogImg} />
                <meta property="og:image" content={ogImg} />
                <meta name="twitter:image" content={ogImg} />
            </Helmet>

            <section style={{ overflowX: "hidden" }} className="uk-section uk-section-large">
                <div className={`uk-container uk-container-small uk-height-large uk-flex uk-flex-center uk-flex-middle`}>
                    <div className="uk-padding uk-width-5-6@m">
                        <div className="uk-width-1-1">
                            <div className="uk-width-1-1 uk-width-auto@s">
                                <h1 className="h1__xlarge uk-margin-remove-bottom uk-margin-remove-adjacent">Hypermix F/uX</h1>
                            </div>
                            <div className="uk-text-lead uk-margin-top">A Sketch <span className="uk-text-nowrap">UI Kit</span> for financial mobile apps.</div>
                        </div>
                    </div>
                </div>
            </section>


            <BackgroundImage
                fluid={heroImage.fluid}
                alt={heroImage.title}
                preserveStackingContext={true}
                className={`${style.heroImage}`}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: `center center`,
                    height: "100%",
                    width: "100%"
                }}
            />
            <div id={slug} className={``}>

                {entries.map((block, i) =>
                    <TwoColumnContent
                        key={`block${i}`}
                        data={block}
                        align={i % 2 === 0}
                    />
                )}
                <section className="uk-section uk-section-large">
                    <div className="uk-container uk-container-xsmall uk-text-center">
                        <p className="uk-h4">Get it for thirty-five</p>
                        <div className="uk-margin">
                            <button
                                onClick={stripeLoader}
                                className="uk-button uk-button-large uk-button-secondary uk-box-shadow-small uk-box-shadow-hover-large"
                                target="_blank"
                                rel="noreferrer">
                                <span>Right here <span uk-icon="icon: arrow-right; ratio: 1.5" /></span>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
export default UxDeliverables;

export const fintechTemplateQuery = graphql`
    query fintechTemplateQuery {
        contentfulProducts(slug: {eq: "fintech-currency-transfer-ui-kit"}) {
            slug
            title
            subtitle {
                subtitle
            }
            mainImage {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
            }
            heroImage {
                localFile {
                    publicURL
                }
                fluid(maxWidth: 1600, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
            }
            contentComponents {
              slug
              textAlign
              content {
                childMarkdownRemark {
                  html
                }
              }
              media {
                file {
                  contentType
                }
                localFile {
                  publicURL
                }
                fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                }
              }
            }
        }
    }
`
